<template>
  <div
    v-if="viewDialog"
    class="view-dialog-overlay"
    @click.self="openMemberDetail.status = false"
  >
    <div class="view-member-modal drawer-active">
      <div class="view-modal-action">
        <fa
          icon="arrow-right-long"
          @click="openMemberDetail.status = false"
        />
       
        <h3>{{ truncateString(fullName,10) }} </h3>
        <!-- <router-link :to="'/edit-member/'+data.userId">
          <fa
            icon="edit"
          />
        </router-link> -->
        <img
          :src="data?.thumbnailProfile?'data:image/jpeg;base64,'+data?.thumbnailProfile:defaultAvatar"
          alt="" 
        >
      </div>

      <div class="view-member-details">
        <div class="member-details-content">
          <h5>Full Name</h5>
          <p>{{ fullName || 'N/A'}}</p>
            

          <h5>Gender</h5>
          <p>{{ capitalizeFirstLetter(data?.gender) || 'N/A' }}</p>

          <!-- <h5>Blood Group</h5>
          <p>{{ data?.bloodGroup || 'N/A' }}</p> -->

          <h5>Phone Number</h5>
          <p>{{ data?.phone?.countryCode || '' }} {{ data?.phone?.number || 'N/A' }}</p>
            
          <h5>Email</h5>
          <p>{{ data?.email || 'N/A' }}</p>

          <h5>Created Date</h5>
          <p>{{ formattedDate(data?.createdAt) || 'N/A' }}</p>

          <h5>Last Updated</h5>
          <p>{{ relatedTime(data?.updatedAt) || 'N/A' }}</p>
  
          <!-- <h5>Chapter</h5>
          <p>{{ data?.chapter || 'N/A' }}</p> -->

          <h5>Status</h5>
          <p>{{ capitalizeFirstLetter(data?.userStatus=='pendingApproval'?'Pending Approval':data?.userStatus) }}</p> 
          
          <button 
            v-show="data?.userStatus === 'active'"
            @click="$emit('deactivateMemberConfirmation',data)"
          >
            Deactivate
          </button>

          <button
            v-show="data?.userStatus === 'deactivated'"
            class="activate-button"
            @click="$emit('activateMemberConfirmation',data)"
          >
            Activate
          </button>

          <button
            v-show="data?.userStatus === 'pendingApproval'"
            class="activate-button"
            @click="$emit('activateMemberConfirmation',data)"
          >
            Approve
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref,watch,inject} from 'vue'
import moment from 'moment';
import { truncateString,formattedDate,capitalizeFirstLetter } from '@/helper/index.js';
const viewDialog = ref(false);
const openMemberDetail = inject('openMemberDetail');
// const sendCredentialMail = inject('sendCredentialMail');
const defaultAvatar = ref(require(`@/assets/images/avatar.svg`));


const emit = defineEmits(['activateMemberConfirmation','deactivateMemberConfirmation']);


const props = defineProps({
	data: {
		type:Object,
		default:()=>{}
	},
	fullName: {
		type:String,
		default:''
	}
	
})




watch(openMemberDetail, (newValue) => {
	console.log("openMemberDetail.status",newValue.status);
	viewDialog.value = newValue.status;

});





const relatedTime = (data) => {
	let localTime = moment.utc(data).toDate();
	localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
	let difference = moment(localTime).fromNow(); 
	return difference;
};
</script>

<style scoped>
.view-dialog-overlay{position: fixed;left: 0;right: 0;bottom: 0;top: 0; background-color: #08080857;width:100%;height: 100vh;z-index:1040;}
 .view-dialog-overlay .view-member-modal{background-color: white;position:absolute;right: 0;z-index: 30000;width: 320px;height: 100vh;border-radius: 16px 0 0 16px;padding: 16px;}
 .view-modal-action{display: flex;
    align-items: center;
    margin-top: 14px;
    margin-bottom: 14px;
    padding-right: 22px;}
.view-modal-action .fa-arrow-right-long{cursor: pointer;}
.view-modal-action h3{margin-left: 12px;}
.view-modal-action .fa-pen-to-square{cursor: pointer;margin-left: 12px;}
.view-modal-action img{width: 60px;height:60px;border-radius:50%; margin-left: auto;object-fit: cover;}
.view-member-details{position: relative;height:calc(100% - 100px) ;overflow-y:auto;}
  .view-member-details::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}
.view-member-details::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #C9C9C9;
}
.member-details-content{position:sticky;text-align: left;}
.member-details-content h5{color: #858585;font-weight: 500;
    font-size: 16px;}
.member-details-content p{color: #242426;font-weight: 600;
    margin-bottom: 23px;}
 .member-details-content button{
    border: 1px solid #EC1B1B;
    color: #EC1B1B;
    padding: 8px 10px;
    display: block;
    margin: 0 auto 12px;
    border-radius: 5px;
    font-weight: 600;
    min-width: 200px;}   
    .approve{background-color: #007CBA;border: 1px solid #007CBA!important;color:white!important;}
    .inline-action{display: flex;align-items:center;}
    .Active,.Inactive{padding: 5px 6px;
      text-align: center;
      font-weight: 800;
      border-radius: 5px;
      border:1px solid #C2C2C2;}   
    .Active{background-color: #CFFFD7;} 
    .Inactive{background-color: #FFCFCF;}  
	.send-credentials{background-color: #007CBA;border:1px solid #007CBA!important;color: #fff !important;}
	button:disabled {
	opacity: 0.5; 
	cursor: not-allowed; 
	}
	.activate-button{border: 1px solid #8BC34A!important;
    color: #8BC34A!important;
	}

	.activate-flat-button{
		background-color: #8BC34A!important;color: white;border-color: #8BC34A!important;
	}

.drawer-active{
      animation: fade-in 500ms forwards;
	}
	@keyframes fade-in{
	0%{
	opacity: 0;
	right: -320px;
	}
	100%{
	opacity: 1;
	right: 0px;
	}
	}
	.drawer-inactive{
      animation: fade-out 500ms forwards;
	}
	@keyframes fade-out{
	0%{
	opacity: 1;
	right: 0px;
	}
	100%{
	opacity: 0;
	right: -320px;
	}
	}

  .attachments-container{width: 100%;
    display: flex;
    flex-wrap: wrap;
    }
  .attachments-thumbnail,a.attachments-thumbnail{display: block; width: 80px;height: 80px;margin-right: 12px; object-fit: cover;background-color: gainsboro;border-radius: 5px;margin-bottom: 12px;}
</style>