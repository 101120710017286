import moment from 'moment';
import store from '../store'
export function capitalizeString(inputString) {

	return inputString.split(' ').map(word => {
		return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
	}).join(' ');
	
}

export function  truncateString(inputString, maxLength){
	if (inputString.length > maxLength) {
		return inputString.slice(0, maxLength) + '...';
	} else {
		return inputString;
	}
}

export function formattedDate(d){
	return moment(d).format('DD-MMM-YYYY');
}

export function formattedDateWithMonth(d){
	return moment(d).format('DD MMM YYYY');
}

export function isoDateformat(d){
	return moment(d).format('YYYY-MM-DD');
}

export function parsedDate(d){
	const parsedDate = moment(d, 'DD-MMM-YYYY');
    // Format the parsed date into the desired format
	const formattedDate = parsedDate.format('YYYY-MM-DD');
	return formattedDate;
}

export function isoStringDateformat(d) {
	// Parse the date string with moment
	// const date = moment(d, 'MMM DD YYYY HH:mm:ss [GMT]Z (z)');
	const date = new Date(d)
	// Format the date to ISO string
	const isoString = date.toISOString();
	return isoString;
}


export function memberActivity(login,activity){
	if(login){
		console.log("login,activity",login,activity);
		if (login > activity) {
			return getRelativeTime(activity) + ' (U)';
		} else if (login < activity) {
			return getRelativeTime(activity) + ' (L)';
		}
  
		return login;
	}else{
		return getRelativeTime(activity) + ' (U)';
	}
}



export function getRelativeTime (data){
	let localTime  = moment.utc(data).toDate();
	localTime = moment(localTime).format('YYYY-MM-DD HH:mm:ss');
	let difference = moment(localTime).fromNow(); 
	return difference;
}

export function listHeading(type){
	switch (type) {
	case 'all':
		return 'Total members'
	case 'completed':
		return 'Active members'
	case 'inactive':
		return 'Inactive members'	
	case 'pending':
		return 'Pending Registration'
	case 'pendingRegistration':
		return 'Pending Registration'   
	case 'pendingApproval':
		return 'Pending Approval' 	  
	default:
		return 'Total Members'
	}
} 

export function showErrorMessage(message, timeout = 2000) {
	store.commit('snackbar/showSnackbar',{
		show:true,
		message:message,
		color:'danger',
		timeout:timeout});
}
  
export function showSuccessMessage(message, timeout = 2000) {
	store.commit('snackbar/showSnackbar',{
		show:true,
		message:message,
		color:'success',
		timeout:timeout});
}

export function formatDuration(minutes){
	const duration = moment.duration(minutes, 'minutes');
	const hours = duration.hours();
	const mins = duration.minutes();
	return `${hours > 0 ? hours + 'hr ' : ''}${mins > 0?mins+'min':''}`;
}

export function formatTime(dateStr){
	const date = moment(new Date(dateStr));
	const formattedTime = date.format('HH:mm'); 
	return formattedTime;
}

// export function formatTime(dateStr) {
// 	const date = moment.utc(new Date(dateStr)); // Set moment to use UTC
// 	const formattedTime = date.format('HH:mm');
// 	return formattedTime;
// }

export function joinName(first,middle,last){
	let fullName = '';
	if(first){
		fullName = first;
	}

	if(middle){
		fullName+=' '+middle;
	}

	if(last){
		fullName+=' '+last;
	}
    
	return fullName;
}

export function generateRandomId() {
	// Get the current date and time
	const now = new Date();
  
	// Extract components from the date and time
	const year = now.getFullYear();
	const month = now.getMonth() + 1; // months are zero-indexed
	const day = now.getDate();
	const hours = now.getHours();
	const minutes = now.getMinutes();
	const seconds = now.getSeconds();
	const milliseconds = now.getMilliseconds();
  
	// Combine date and time components to a single numeric value
	const dateTimeValue = `${year}${month}${day}${hours}${minutes}${seconds}${milliseconds}`;
  
	// Convert the combined value to an integer
	const dateTimeInt = parseInt(dateTimeValue, 10);
  
	// Generate a random number between 0 and 9999 (4 digits)
	const randomNumber = Math.floor(Math.random() * 10000);
  
	// Combine the dateTimeInt and randomNumber, and get the last 5 digits
	const combinedValue = dateTimeInt + randomNumber;
	const randomId = combinedValue.toString().slice(-5);
  
	return randomId;
}

export function capitalizeFirstLetter(string) {
	if (!string) return '';
	return string.charAt(0).toUpperCase() + string.slice(1);
}